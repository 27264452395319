.verificationRequestWrapper {
  border: 1px solid #ccc;
  border-radius: 5px;

  > .verificationRequestHeading {
    color: #262626;
    background-color: #f2f2f2;
    border-radius: 5px 5px 0 0;
    display: flex;

    > .verificationRequestHeadingNumber {
      position: absolute;
      background-color: white;
      padding: 3px 15px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      border-radius: 45px;
      margin-top: -13px;
      margin-left: calc(20% - 20px);
      font-size: 13px;
      color: #262626;

      @media only screen and (max-width: 600px) {
        margin-left: calc(50% - 100px);
      }
    }

    > .headingVerbiage {
      text-align: center;
      padding: 15px 0 10px 0;
      flex: 1;
    }

    > .verificationRequestOutcome {
      width: 50px;
      height: 50px;
      margin-right: 20px;
      text-align: center;

      &.positive {
        background-color: #39da20;
      }

      &.negative {
        background-color: #da2020;
      }

      > .verificationRequestOutcomeIcon {
        width: 20px;
        margin-top: 13px;
      }

      > .headingVerbiage {
        display: block;
      }
    }

    &.outcome {
      background-color: white;

      > .headingVerbiage {
        color: #b3b3b3;
      }

      > .verificationRequestHeadingNumber {
        margin-top: -15px;
      }
    }
  }

  > .verificationRequestSubheadingRow {
    display: flex;
    width: 100%;
    justify-content: space-around;

    @media only screen and (max-width: 600px) {
      margin-top: 5px;
      display: grid;
      grid-template-columns: auto auto;
      grid-auto-flow: initial;
      grid-column-gap: 20px;
      grid-row-gap: 10px;
    }

    > .verificationRequestSubheadingCell {
      > .verificationRequestSubheadingCellLabel {
        color: #d5d5d5;
        font-size: 12px;
        text-align: center;

        @media only screen and (max-width: 600px) {
          font-size: 10px;
        }
      }

      > .verificationRequestSubheadingCellContent {
        color: #8b8b8b;
        font-size: 15px;
        text-transform: capitalize;

        @media only screen and (max-width: 600px) {
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }

  > .verificationRequestDetailWrapper {
    margin-top: 10px;
    display: grid;
    padding: 0 10px;
    grid-template-columns: auto auto;
    grid-auto-flow: initial;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    margin-bottom: 20px;

    @media only screen and (max-width: 600px) {
      grid-template-columns: auto;
    }

    > .verificationRequestDetailElement {
      border: 1px solid #ccc;
      display: flex;
      border-radius: 5px;

      > .verificationRequestDetailElementLabel {
        flex: 1;
        color: #5e5e5e;
        border-radius: 5px 0 0 5px;
        background-color: #f3f3f3;
        text-align: right;
        padding: 5px 10px 5px 0;
      }

      .verificationRequestDetailElementNumber {
        color: black;
        font-weight: 500;
        min-width: 80px;
        padding: 5px 0 5px 10px;
      }
    }
  }

  > .verificationRequestActionsRow {
    display: flex;
    justify-content: space-between;
    padding: 10px;

    @media only screen and (max-width: 600px) {
      display: block;
      padding-top: 0;
    }

    > .verificationAction {
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
      transition: all 0.2s;
      cursor: pointer;
      background-color: white;
      border-radius: 5px;
      display: flex;

      &.negative {
        &:hover {
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
          color: white;
        }
      }

      &.positive {
        &:hover {
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
          color: white;
        }
      }

      @media only screen and (max-width: 600px) {
        margin-top: 10px;
      }

      > .verificationActionIconWrapper {
        border-radius: 4px 0 0 4px;
        width: 22px;
        height: 22px;
        text-align: center;
        padding: 10px;

        &.negative {
          background-color: #da2020;
        }

        &.positive {
          background-color: #39da20;
        }

        > .verificationActionIcon {
          height: 20px;
          position: relative;
          top: 2px;
        }
      }

      > .verificationActionLabel {
        padding: 10px 20px;
        color: #cdcdcd;

        @media only screen and (max-width: 600px) {
          padding-left: 30px;
        }

        > .emphasis {
          color: #3e3e3e;
        }
      }
    }
  }
}
