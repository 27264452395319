@keyframes pulse {
  0% {
    background-color: rgba(0, 0, 0, 0.2);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.3);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

$compensation_negative: #b24949;
$compensation_positive: #47cb51;

$validity_negative: #a1a1a1;
$validity_positive: #2891c9;

.wrapper {
  width: 100%;
  margin: 10px 0;

  > .dataBarRow {
    margin-bottom: 10px;

    > .barLabel {
      color: #d0d0d0;
      font-size: 12px;
      padding: 5px;
      text-align: center;
    }

    > .barWrapperSkeleton {
      border-radius: 30px;
      animation: pulse 8s infinite;
      transition: all 0.2s;
      text-align: center;
      font-size: 10px;
      padding: 5px;
      color: rgb(255, 255, 255);
    }

    > .barWrapper {
      height: 20px;
      border-radius: 30px;
      color: white;
      font-size: 13px;
      font-weight: 500;
      transition: all 0.4s;
      background-color: $compensation_negative;

      &.validity {
        background-color: $validity_positive;
      }

      &.personal {
        background-color: $compensation_negative;
      }

      > .belowBar {
        height: 19px;
        border-radius: 20px;
        float: left;
        padding-left: 5px;
        transition: all 0.4s;
        border-radius: 30px 0 0 30px;
        border-right: 4px solid white;
        background-color: $compensation_positive;
        font-size: 12px;
        padding-top: 1px;

        &.validity {
          background-color: $validity_negative;
        }

        &.personal {
          border-radius: 30px 0 0 30px;
          box-shadow: 0 0 3px $compensation_positive;
          border-right: 4px solid white;
          background-color: $compensation_positive;
        }
      }

      > .upperNumber {
        float: right;
        padding-right: 5px;
        font-size: 12px;
        padding-top: 1px;
      }
    }

    > .actionRow {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;

      > .actionButton {
        padding: 5px 0;
        width: 150px;
        text-align: center;
        font-size: 15px;
        background-color: white;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.2s;
        
        &.negative {
          color: $compensation_negative;

          &.active {
            color: white;
            background-color: $compensation_negative;
            box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
          }
        }

        &.positive {
          color: $compensation_positive;
          
          &.active {
            color: white;
            background-color: $compensation_positive;
            box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
          }
        }

        &.negativeValidity {
          color: $validity_negative;
          
          &.active {
            color: white;
            background-color: $validity_negative;
            box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
          }
        }
        
        &.positiveValidity {
          color: $validity_positive;
          
          &.active {
            color: white;
            background-color: $validity_positive;
            box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
          }
        }
        
        &:hover {
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
        }
      }
    }
  }

  > .actionRowSecondary {
    display: flex;
    margin-top: 15px;
    border-radius: 5px;

    > .actionButtonSecondary {
      padding: 5px 5px;
      text-align: center;
      font-size: 12px;
      background-color: white;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      width: 100%;
      color: #c4c4c4;

      &.message {
        color: #4a6ab7;
      }

      &.negative {
        &.active {
          color: white;
          background-color: $compensation_negative;
          box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
        }
      }

      &.positive {
        &.active {
          color: white;
          background-color: $compensation_positive;
          box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
