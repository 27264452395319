.pageWrapper {
  max-width: 500px;
  margin: auto;
  text-align: center;
  padding: 40px 0;
  overflow-x: hidden;

  @media only screen and (max-width: 600px) {
    width: calc(100% - 40px);
    margin: auto;
  }
}

.logo {
  width: 300px;
}

.introBlurb {
  text-align: left;
  font-weight: 300;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 20px;
  background-color: white;
  margin-top: 20px;
}

.formWrapper {
  width: 100%;
  background-color: white;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 0 0 30px 0;
  min-height: 200px;
  margin-top: 30px;
  clear: both;

  .formWrapperLabel {
    color: white;
    background-color: #aaa;
    display: inline-block;
    border-radius: 30px;
    padding: 2px 15px;
    position: relative;
    top: -13px;
    font-size: 13px;
  }
}

.formElementLabel {
  margin-top: 10px;
  padding: 5px;
  text-align: left;
  color: #343434;
  font-size: 13px;
  margin-left: 100px;

  @media only screen and (max-width: 500px) {
    margin-left: 10%;
  }

  @media only screen and (max-width: 400px) {
    margin-left: 5%;
  }

  >.formElementLabelSecondary {
    font-size: 10px;
    color: #bbb;
    bottom: 2px;
    position: relative;
  }
}

.formElementDollar {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #aaa;
  color: white;
  font-size: 21px;
  position: absolute;
  margin-top: 7px;
  margin-left: 85px;

  @media only screen and (max-width: 500px) {
    margin-left: 5%;
  }
  @media only screen and (max-width: 450px) {
    margin-left: 0%;
  }
  @media only screen and (max-width: 400px) {
    margin-left: -3%;
  }
}

.formElement {
  max-width: 300px;
  margin: auto;
  font-family: "Open Sans";

  &.error {
    border: 1px solid red;
    border-radius: 5px;
  }
}

.toggleSelectWrapper {
  width: 300px;
  background-color: #bbb;
  border-radius: 50px;
  display: flex;
  margin: auto;
  font-size: 12px;

  > .toggleSelectActive {
    position: absolute;
    color: #aaa;
    padding: 10px 20px;
    background-color: white;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 40px;
    font-weight: 400;
    transition: all 0.3s;

    &.associate {
      margin-left: 0;
    }

    &.ofCounsel {
      margin-left: 90px;
    }

    &.partner {
      margin-left: 210px;
    }
  }

  > .toggleSelectInactive {
    font-weight: 300;
    color: white;
    padding: 10px;
    border-radius: 40px;
    flex: 1;
    text-align: center;
    cursor: pointer;
  }
}

.formTextInput {
  width: 240px;
  margin: auto;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 5px;
  color: rgb(39, 39, 39);
  font-family: "Open Sans";
  padding: 10px 30px;
  font-size: 17px;

  &::placeholder {
    color: #aaa;
    font-weight: 300;
  }

  &.error {
    border: 1px solid red;
  }
}

.submit {
  color: white;
  background-color: #259b38;
  border: none;
  margin-top: 30px;
  margin-bottom: 60px;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 40px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  padding: 10px 40px;
  font-family: "Open Sans";

  &:hover {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  }
}

.formElementFinancialRow {
  > .slider {
    -webkit-appearance: none;
    height: 25px;
    background: #eee;
    outline: none;
    opacity: 0.7;
    border-radius: 30px;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    width: 300px;

    &:hover {
      opacity: 1;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 25px;
      border-radius: 15px;
      background: #777;
      cursor: pointer;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    }

    &::-moz-range-thumb {
      width: 25px;
      height: 25px;
      background: #777;
      cursor: pointer;
    }
  }

  > .compensationFairnessLabels {
    width: 290px;
    margin: auto;
    display: flex;
    justify-content: space-between;

    > .compensationFairnessLabel {
      font-size: 12px;
      color: #aaa;
    }
  }
}
