.wrapper {
  // width: 300px;
  right: 45px;
  border-radius: 5px;
  transition: all 0.2s;
  bottom: 0;
  margin: auto;
  z-index: 100;

  @media only screen and (max-width: 600px) {
    // width: calc(100% - 40px);
    // height: calc(100% - 20px);
    right: 10px;
    top: 10px;
    bottom: auto;
  }

  > .body {
    height: 100%;
  }

  @media only screen and (max-width: 600px) {
    // right: 10px;
  }

  > .body {
    transition: all 0.2s;
    width: calc(100% - 0px);
    background-color: white;
    overflow: hidden;

    > .conversation {
      width: 100%;
      border-bottom: 1px solid #ccc;

      cursor: pointer;

      &:hover {
        background-color: rgb(245, 245, 245);
      }

      > .conversationMemberInfo {
        text-align: center;
        font-size: 16px;
        padding: 5px 0;

        > .conversationHasUnseenIcon {
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background-color: #fcd779;
          position: absolute;
          margin-top: -15px;
          margin-left: 10px;
          box-shadow: 0 0 3px #fcd779;
        }
      }

      > .conversationPreview {
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 5px;
        white-space: nowrap;
        border-top: 1px solid #ddd;
        color: #444;
        height: 15px;
        font-size: 13px;
      }
    }
  }
}

.detailWrapper {
  width: 300px;
  right: 260px;
  bottom: 0;
  border-radius: 5px 5px 0 0;
  z-index: 101;

  @media only screen and (max-width: 600px) {
    width: calc(100% - 0px);
    height: calc(100% - 20px);
    right: 10px;
    bottom: 10px;
    border-radius: 10px;
  }

  > .detailHeader {
    width: calc(100% - 10px);
    position: fixed;
    top: 0px;
    border-bottom: 1px solid #eee;
    text-align: center;
    padding: 16px 5px;
    font-weight: 500;
    background-color: #eee;
    display: block;

    @media only screen and (max-width: 600px) {
      font-size: 16px;
    }

    > .headerBack {
      height: 20px;
      position: absolute;
      left: 10px;
      top: 10px;
      cursor: pointer;

      @media only screen and (min-width: 600px) {
        display: none;
      }
    }
  }

  > .body {
    padding: 10px 0;
    overflow-y: scroll;
    height: 288px;
    background-color: white;
    overflow-x: hidden;
    margin-bottom: 30px;

    @media only screen and (max-width: 600px) {
      margin-bottom: 30px;
      height: calc(100% - 116px);
      padding-bottom: 30px;
    }

    > .messageWrapper {
      width: calc(100% - 20px);
      padding: 5px 10px;
      display: table;

      > .messageBubble {
        padding: 5px 8px;
        display: table;
        max-width: 200px;
        border-radius: 10px;
        line-break: loose;

        @media only screen and (max-width: 600px) {
          font-size: 14px;
        }

        &.userAuthored {
          background-color: #416bbd;
          color: white;
          float: right;
        }

        &.otherUserAuthored {
          color: #888;
          background-color: #eee;
        }

        > a {
          text-decoration: underline;
        }
      }
    }
  }

  > .chatDetailEmptyState {
    height: 400px;
    text-align: center;
    padding-top: 200px;

    > .primary {
      font-size: 18px;
      font-style: italic;
      color: #777;
    }

    > .secondary {
      color: #ccc;
      font-size: 14px;
    }
  }

  > .messageInput {
    width: calc(100% - 20px);
    border: none;
    border-top: 1px solid #bbb;
    position: fixed;
    font-family: "Open Sans";
    padding: 5px 10px;
    font-size: 15px;
    bottom: 0;

    @media only screen and (max-width: 600px) {
      font-size: 16px;
      height: 25px;
      width: calc(100% - 50px);
      padding-right: 30px;
    }

    &:focus-visible {
      border-color: #777;
      outline: none;
    }
  }

  > .messageSendButton {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: white;
    position: fixed;
    z-index: 100;
    bottom: 3px;
    right: 3px;
    text-align: center;
    justify-items: center;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);

    @media only screen and (min-width: 600px) {
      display: none;
    }

    > .messageSendButtonIcon {
      width: 18px;
      height: 18px;
      margin-top: 7px;
      margin-left: 2px;
    }
  }
}
