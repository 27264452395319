.container {
  display: flex;
  margin: 0;
  height: 100%;
}

.frameLeft {
  flex: 1;
  background-size: cover;
  background-position: center;
  height: 100%;

  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.upper {
  height: 50%;
  text-align: center;
}

.logo {
  height: 50px;
  position: relative;
  top: calc(50% - 25px);
}

// .lower {
// }

.frameRight {
  flex: 1;
}

.inputFrame {
  margin: auto;
  width: 400px;
  text-align: center;
}

.input {
  width: 300px;
  margin: auto;
  clear: both;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  padding: 10px;
  background-color: transparent;
  text-align: center;
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 20px;

  &:focus-visible {
    background-color: white;
    outline: none;
    border-color: #ca9d75;
  }

  &::placeholder {
    color: #bbb;
  }
}

.explainationWrapper {
  width: 100%;
  text-align: center;
}

.lock {
  width: 20px;
}

.explaination {
  font-weight: 300;
  max-width: 320px;
  margin: auto;
  color: #cccccc;
  font-size: 12px;
}

.warning {
  font-size: 12px;
  color: rgb(169, 169, 169);
  padding: 5px;
  display: inline-block;
  border-radius: 5px;
  border: 1px solid rgb(235, 144, 144);
  margin-top: 10px;
}

.warningEmpty {
  height: 33px;
  display: block;
  width: 100%;
}

.inputHeading {
  font-size: 15px;
  width: 100%;
  text-align: center;
  color: #454545;
  margin-bottom: 10px;
}

.actionButton {
  color: white;
  background-color: #4e66c6;
  border: none;
  outline: none;
  border-radius: 20px;
  padding: 10px 20px;
  margin-top: 30px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  }
}
