.mainContent {
  padding: 10px;
  width: calc(100% - 20px);
  background-color: rgb(220, 220, 220, 0.6);

  @media only screen and (min-width: 600px) {
    background-color: rgb(220, 220, 220, 0);

    max-width: 600px;
    margin: auto;
    margin-top: 30px;
  }

  > .mainContentHeaderRow {
    width: 100%;
    padding-bottom: 18px;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 600px) {
      display: block;
    }

    > .mainContentHeaderLabel {
      font-size: 20px;
      color: #525252;

      @media only screen and (max-width: 600px) {
        font-size: 15px;
      }
    }

    > .mainContentHeaderActions {
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: 600px) {
        margin-top: 20px;
        // display: block;
      }

      > .tableToggleWrapper {
        margin-right: 20px;
        display: flex;
        border-radius: 3px;
        background-color: #f6f6f6;

        > .tableToggleActive {
          position: absolute;
          background-color: #13469f;
          border-radius: 3px;
          color: white;
          font-size: 13px;
          padding: 5px 15px;
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
          transition: all 0.2s;
          @media only screen and (max-width: 600px) {
            font-size: 10px;
          }

          &.left {
            margin-left: 0;
          }

          &.right {
            margin-left: 80px;
          }
        }

        > .tableToggleInactive {
          font-size: 13px;
          padding: 5px 15px;
          color: rgb(94, 94, 94);
          cursor: pointer;

          @media only screen and (max-width: 600px) {
            font-size: 10px;
          }
        }
      }

      > .tableFilter {
        font-family: "Open Sans";
        border: 1px solid #ccc;
        border-radius: 30px;
        padding: 3px 15px;

        &::placeholder {
          color: #ccc;
          font-weight: 300;
        }
      }
    }
  }

  > .userDataDivider {
    height: 4px;
    width: 100%;
    clear: both;
    margin-bottom: 25px;
    background-color: #ddd;
    border-radius: 2px;
  }
}

.titleCase {
  text-transform: capitalize;
}

.copyInviteCodeRow {
  width: 100%;
  padding: 10px 0;
  margin-bottom: 30px;
  display: block;

  > .copyInviteCodeVerbiage {
    font-size: 15px;
    color: #888;
    text-align: center;
    padding-bottom: 20px;
    text-transform: uppercase;
  }
}

.footer {
  height: 20px;
  text-align: center;
  font-size: 12px;
  color: #777;
  width: 100%;
}
