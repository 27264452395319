@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

.header-wrapper {
  width: calc(100% - 60px);
  height: 50px;
  padding: 10px 30px;
  background-color: white;

  @media only screen and (max-width: 600px) {
    width: calc(100% - 20px);
    padding: 10px;
    height: auto;
  }
}

.header-logo {
  height: 50px;

  @media only screen and (max-width: 750px) {
    height: 30px;
  }
}

.header-button-wrapper {
  float: right;
  width: 350px;
  position: relative;
  top: 15px;
  display: flex;
  justify-content: end;

  @media only screen and (max-width: 600px) {
    width: auto;
    top: 10px;
  }
}

.header-button {
  font-size: 15px;
  color: #c0c0c0;
  margin-left: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s;
  display: block;
  padding: 0;
  height: 1em;
  line-height: 1em;
  font-family: "Open Sans";

  @media only screen and (max-width: 600px) {
    font-size: 14px;
    margin-left: 5px;
  }

  &:hover {
    color: #8a8a8a;
  }

  > .conversationHasUnseenIcon {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #fcd779;
    position: absolute;
    animation: pulse 2s infinite;
    margin-top: -10px;
    margin-left: -5px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  }

  &.active {
    color: #505050;
    text-decoration: underline;
    font-weight: 500;

    &:hover {
      color: #383838;
    }
  }
}
