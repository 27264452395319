.copyInviteWrapper {
  width: 370px;
  margin: auto;
  display: flex;

  > .copyInviteInput {
    border-radius: 5px;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 30px 0 0 30px;
    padding: 8px 15px;
    background-color: white;
    font-family: "Open Sans";
    color: #aaa;
    margin-right: -10px;
    font-size: 14px;
    float: left;
    width: 170px;

    @media only screen and (max-width: 800px) {
    }
  }

  > .copyInviteButton {
    outline: none;
    border: none;
    background-color: #618fff;
    font-family: "Open Sans";
    color: white;
    cursor: pointer;
    padding: 15px 15px;
    border-radius: 25px;
    font-size: 10px;
    position: relative;
    top: 2px;
    float: left;
    left: -8px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    transition: all 0.2s;
    height: 38px;
    flex: 1;
    padding-top: 13px;

    &:hover {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
    }

    > .copyInviteIcon {
      width: 10px;
      margin-right: 5px;
    }
  }
}
