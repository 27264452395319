.emphasis {
  font-weight: 500;
}

.container {
  display: flex;
  margin: 0;
  height: 100%;
}

.frameLeft {
  flex: 1;
  background-color: white;
}

.upper {
  height: 25%;
  text-align: center;
}

.logo {
  height: 50px;
  position: relative;
  top: calc(50% - 25px);
}

.frameRight {
  flex: 1;
  background-size: cover;
  background-position: center;
  height: 100%;

  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.infoFrame {
  margin: auto;
  width: 500px;
  text-align: center;
  padding: 30px;
  border: 1px solid #cecece;
  border-radius: 5px;

  > .link {
    text-decoration: underline;
  }

  @media only screen and (max-width: 600px) {
    width: calc(100% - 40px);
    padding: 10px;
  }
}

.infoVerbiage {
  font-weight: 300;
  color: #141414;
  font-family: "Open Sans";
  font-size: 18px;
  padding-bottom: 20px;
}
