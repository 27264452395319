@for $i from 1 through 6 {
  @keyframes preload-show-#{$i} {
    from {
      transform: rotateZ(60 * $i + deg) rotateY(-90deg) rotateX(0deg);
      border-left-color: black;
    }
  }
  @keyframes preload-hide-#{$i} {
    to {
      transform: rotateZ(60 * $i + deg) rotateY(-90deg) rotateX(0deg);
      border-left-color: black;
    }
  }

  @keyframes preload-cycle-#{$i} {
    $startIndex: $i * 5;
    $reverseIndex: (80 - $i * 5);

    #{$startIndex * 1%} {
      transform: rotateZ(60 * $i + deg) rotateY(90deg) rotateX(0deg);
      border-left-color: black;
    }
    #{$startIndex + 5%},
    #{$reverseIndex * 1%} {
      transform: rotateZ(60 * $i + deg) rotateY(0) rotateX(0deg);
      border-left-color: #ca9d75;
    }

    #{$reverseIndex + 5%},
    100% {
      transform: rotateZ(60 * $i + deg) rotateY(90deg) rotateX(0deg);
      border-left-color: black;
    }
  }
}

@keyframes preload-flip {
  0% {
    transform: rotateY(0deg) rotateZ(-60deg);
  }
  100% {
    transform: rotateY(360deg) rotateZ(-60deg);
  }
}

.preloaderWrapper {
  top: 50%;
  margin: auto;
  font-size: 20px;
  margin-top: 300px;
  display: block;
  height: 40px;
  text-align: center;

  > .preloader {
    // width: 40px;
    height: 40px;
  }

  > .preloaderText {
    color: white;
    transition: all 0.2s;
  }
}
.loading-gauze {
  width: 100%;
  height: 100%;
  background-size: 200%;
  background-repeat: no-repeat;
  background-position: top left;
  position: absolute;
  top: 0;
  z-index: 1000;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
