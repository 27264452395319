.heading {
  margin: auto;
  width: calc(100% - 100px);
  background-color: white;
  margin: 30px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 20px;
  display: flex;

  @media only screen and (max-width: 600px) {
    margin: 10px;
    width: calc(100% - 60px);
    display: grid;
    grid-template-columns: auto auto;
    grid-auto-columns: minmax(0, 1fr);
    grid-row-gap: 10px;
    margin-bottom: 20px;
  }
}

.headingSection {
  margin-right: 20px;
  @media only screen and (max-width: 600px) {
    margin-right: 20px;
  }

  > .headingSectionLabel {
    color: #d0d0d0;
    font-size: 13px;

    @media only screen and (max-width: 600px) {
      font-size: 12px;
    }
  }

  > .headingSectionBody {
    color: #717171;
    font-size: 20px;

    @media only screen and (max-width: 600px) {
      font-size: 15px;
    }

    &.role {
      text-transform: capitalize;
    }
  }
}

.headingMessage {
  background-color: #416bbd;
  color: white;
  border: none;
  outline: none;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 40px;
  font-family: "Open Sans";
  font-weight: 500;
  padding: 3px 12px;
  position: absolute;
  right: 48px;
  margin-top: 12px;
  transition: all 0.2s;

  @media only screen and (max-width: 600px) {
    position: relative;
    right: 0;
    padding: 6px 16px;
  }

  &:hover {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  }
}

.verifiedBadge {
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  padding: 1px 8px;
  color: #333ea0;
  border-radius: 3px;
  border: 1px solid #333ea0;

  > .verifiedIcon {
    width: 15px;
    margin-right: 5px;
    position: relative;
    top: 2px;
  }
}

.notVerifiedBadge {
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  padding: 1px 8px;
  border-radius: 3px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: #333ea0;
    color: white;
    border-color: transparent;
  }
}

.verifyingBadge {
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  padding: 1px 8px;
  border-radius: 3px;
  border: 1px solid rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  transition: all 0.3s;
}

.membershipId {
  font-weight: 500;
}

.tabWrapper {
  display: flex;
  margin-left: 30px;

  @media only screen and (max-width: 600px) {
    margin-left: 15px;
  }

  > .tab {
    color: #cdcdcd;
    font-weight: 500;
    margin-right: 10px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      color: #bbb;
    }

    &.active {
      color: #393939;
      text-decoration: underline;

      &:hover {
        color: #1f1f1f;
      }
    }
  }
}

.profileContent {
  margin: auto;
  width: calc(100% - 60px);
  margin-top: 30px;

  @media only screen and (max-width: 600px) {
    width: calc(100% - 30px);
    margin-top: 10px;
  }
}
