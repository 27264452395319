.gridContainerMain {
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  display: grid;
  grid-gap: 20px;
  margin-bottom: 30px;

  @media only screen and (max-width: 600px) {
    grid-template-columns: auto;
    grid-auto-flow: initial;
    margin-bottom: 20px;
  }
}

.gridContainerSecondary {
  grid-auto-columns: minmax(0, 1fr);
  grid-template-columns: auto auto auto auto;
  display: grid;
  grid-gap: 20px;

  @media only screen and (max-width: 600px) {
    grid-auto-flow: column;
    grid-template-columns: auto auto;
    grid-auto-flow: initial;
    grid-column-gap: 20px;
    margin-bottom: 20px;
  }
}

.cellWrapper {
  background-color: white;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 20px;

  @media only screen and (max-width: 600px) {
    // margin-bottom: 20px;
  }

  > .cellHeadingRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 600;

    > .cellHeadingMain {
      color: #7b7b7b;
      font-size: 15px;
    }

    > .cellHeadingSecondary {
      color: #d2d2d2;
      font-size: 8px;
      padding-top: 2px;
    }
  }

  > .cellMainContent {
    font-size: 80px;
    font-weight: 500;
    text-align: center;
    font-family: "Alata";
    width: 100%;
    color: #898989;
  }

  > .cellHeadingSubheading {
    color: #7b7b7b;
    font-size: 13px;
  }

  > .cellMainMinorContent {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    font-family: "Alata";
    color: #898989;
    padding: 30px 0;

    @media only screen and (max-width: 600px) {
      padding: 15px 0;
    }
  }
}
