.emphasis {
  font-weight: 500;
}

.container {
  display: flex;
  margin: 0;
  height: 100%;
}

.frameLeft {
  flex: 1;
  background-color: white;
}

.upper {
  height: 25%;
  text-align: center;
}

.logo {
  height: 50px;
  position: relative;
  top: calc(50% - 25px);
}

.frameRight {
  flex: 1;
  background-size: cover;
  background-position: center;
  height: 100%;

  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.validClaim {
  width: 350px;
  padding: 20px;
  margin: auto;
  text-align: center;
  color: rgb(146, 194, 146);
}

.infoFrame {
  margin: auto;
  width: 500px;
  text-align: center;
  padding: 30px;
  border: 1px solid #cecece;
  border-radius: 5px;

  @media only screen and (max-width: 600px) {
    width: calc(100% - 40px);
    padding: 10px;
  }
}

.infoVerbiage {
  font-weight: 300;
  color: #141414;
  font-family: "Open Sans";
  font-size: 18px;
  padding-bottom: 20px;
}

.confirmWrapper {
  > .confirm {
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    outline: none;
    font-family: "Open Sans";
    border: none;
    font-size: 15px;
    color: #141414;
    padding: 10px 80px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
      color: white;
      background-color: #ca9d75;
    }
  }
}

.unqualifiedApplicatedWrapper {
  width: 100%;
  margin-top: 60px;
  text-align: center;

  > .unqualifiedApplicated {
    cursor: pointer;
    background-color: white;
    margin: auto;
    outline: none;
    font-family: "Open Sans";
    border: 1px solid #dcdcdc;
    font-size: 13px;
    color: #a6a6a6;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.2s;

    &:hover {
      background-color: rgb(245, 245, 245);
      color: rgb(97, 97, 97);
    }
  }
}

.recruiterRow {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 40px;
  width: calc(50% - 80px);
  background-color: #f7f7f7;

  @media only screen and (max-width: 600px) {
    width: calc(100% - 40px);
    padding: 20px;
  }

  > .recruiterVerbiageTop {
    color: #212121;
    font-size: 15px;
  }

  > .recruiterVerbiageBottom {
    margin-top: 5px;
    color: #969696;
    font-weight: 300;
    font-size: 12px;
  }
}
