.header {
  width: calc(100% - 40px);
  padding: 20px;
  background-color: white;
}

.logo {
  width: 200px;
}

.first {
  background-size: cover;
  height: 300px;
  color: white;
}

.firstVerbiage {
  margin-left: 100px;
  padding-top: 100px;
  font-family: Alata;
}

@media only screen and (max-width: 600px) {
  .firstVerbiage {
    margin-left: 50px;
  }
}

.firstVerbiageTop {
  font-size: 2.5rem;
}

.firstVerbiageBottom {
  font-size: 1.5rem;
}

.section {
  padding: 80px 0;
  background-size: cover;
  background-position: center;

  &.light {
    background-color: white;
  }

  &.dark {
    background-color: #303030;
  }

  &.gold {
    background-color: #ca9d75;
  }

  @media only screen and (max-width: 600px) {
    padding: 40px 0;
  }

  > .sectionHeader {
    width: 100%;
    font-size: 25px;
    text-align: center;
    font-family: "Alata";
    padding-bottom: 60px;

    &.dark {
      color: #393939;
    }

    &.light {
      color: white;
    }
  }

  > .sectionBody {
    max-width: 500px;
    font-size: 15px;
    color: #999999;
    margin: auto;

    &.dark {
    }

    &.light {
      color: white;
    }

    @media only screen and (max-width: 600px) {
      width: calc(100% - 40px);
    }
  }
}

.footer {
  background-color: #1a1a1a;
  font-weight: 300;
  color: #959595;
  display: flex;
  justify-content: space-between;
  padding: 30px;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
    padding: 20px;
  }

  > .trademark {
  }

  > .linksWrapper {
    > .link {
      text-decoration: underline;
      margin-left: 10px;
      transition: all 0.2s;

      &:hover {
        color: white;
      }
    }
  }
}
