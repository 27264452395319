.infoPanel {
  max-width: 400px;
  border: 1px solid #ccc;
  background-color: white;
  margin: auto;
  margin-top: 100px;
  font-size: 15px;
  font-weight: 300;
  padding: 20px;
  border-radius: 10px;
  color: rgb(54, 54, 54);

  @media only screen and (max-width: 600px) {
    width: calc(100% - 80px);
  }

  > .emphasis {
    font-weight: 500;
  }
}
