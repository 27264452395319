@import url("https://fonts.googleapis.com/css2?family=Alata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap");

body {
  background-color: #f5f5f5;
  font-family: "Open Sans";
  font-weight: 400;
  -webkit-overflow-scrolling: none;
}

a {
  text-decoration: none;
  color: inherit;
  font-family: "Open Sans";
}

button {
  font-family: "Open Sans";
}

.hide-on-mobile {
  @media only screen and (max-width: 650px) {
    display: none;
  }
}

.hide-on-desktop {
  @media only screen and (min-width: 650px) {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .application-wrapper {
    overflow-y: hidden;
  }
}
