.container {
  margin: auto;
  width: calc(100% - 40px);
  background-color: white;
  margin-bottom: 30px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 20px 20px 10px 20px;

  @media only screen and (max-width: 600px) {
  }

  > .dataWrapper {
    display: grid;
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
    grid-auto-flow: initial;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
}

.contextCell {
  border: 1px solid rgb(236, 236, 236);
  border-radius: 3px;
  text-align: center;
  padding: 5px;

  > .contextCellValue {
    color: #717171;
    font-size: 15px;
    font-weight: 500;
  }

  > .contextCellLabel {
    color: #d0d0d0;
    font-size: 10px;
  }
}
