.coreCell {
  border: 1px solid rgb(236, 236, 236);
  border-radius: 3px;
  text-align: center;

  > .coreCellContent {
    padding: 5px;
    display: flex;

    > .coreNumber {
      flex: 2;
      color: #717171;
      font-size: 22px;
      font-family: "Alata";
      padding-top: 5px;
      padding-bottom: 5px;
    }

    > .coreNumberMeta {
      flex: 1;

      > .coreNumberMetaPoint {
        border: 1px solid #eee;
        border-radius: 2px;
        display: flex;
        margin-top: 10px;
        margin-right: 10px;
        font-size: 10px;

        > .coreNumberMetaPointKey {
          color: #6c6c6c;
          padding: 3px;
          flex: 1;
        }

        > .coreNumberMetaPointValue {
          flex: 1;
          padding: 3px;
          background-color: #dddddd;
          border-radius: 0 2px 2px 0;
          color: white;
        }
      }
    }
  }

  > .coreLabel {
    background-color: #f2f2f2;
    text-align: center;
    border-radius: 3px 0 0 3px;
    padding: 5px;
    font-size: 8px;
    color: #6c6c6c;
  }
}
