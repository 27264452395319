.gridContainerMain {
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  display: grid;
  grid-gap: 20px;
  margin-bottom: 30px;

  @media only screen and (max-width: 600px) {
    grid-template-columns: auto;
    grid-auto-flow: initial;
    grid-column-gap: 20px;
    margin-bottom: 20px;
  }
}

.cellWrapper {
  background-color: white;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 20px;

  > .cellHeadingRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 600;

    > .cellHeadingMain {
      color: #7b7b7b;
      font-size: 15px;
    }
    > .cellHeadingSecondary {
      color: #d2d2d2;
      font-size: 8px;
      padding-top: 2px;
    }
  }

  > .cellMainContent {
    width: 100%;
    color: #898989;
    padding-top: 10px;

    > .cellSubheading {
      font-size: 13px;
      text-align: center;
    }

    > .copyInviteWrapper {
      width: 100%;
      margin-top: 5px;
      display: flex;

      > .copyInviteInput {
        border-radius: 5px;
        outline: none;
        border: 1px solid #ccc;
        border-radius: 5px 0 0 5px;
        padding: 8px 15px;
        background-color: white;
        font-family: "Open Sans";
        color: #aaa;
        font-size: 14px;
        flex: 1;
        float: left;
      }

      > .copyInviteButton {
        outline: none;
        border: none;
        background-color: #618fff;
        font-family: "Open Sans";
        color: white;
        cursor: pointer;
        padding: 12px 15px;
        border-radius: 5px;
        font-size: 10px;
        position: relative;
        float: left;
        left: -6px;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
        transition: all 0.2s;

        @media only screen and (max-width: 600px) {
          padding: 6px 6px;
          min-width: 130px;
        }

        &:hover {
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
        }

        > .copyInviteIcon {
          width: 10px;
          margin-right: 5px;
        }
      }
    }

    > .verificationRequestContainer {
      margin-top: 10px;
    }

    > .noVerificationRequests {
      text-align: center;
      font-style: italic;
      padding: 20px 0;
    }

    > .requestVerificationButton {
      background-color: #416bbd;
      color: white;
      outline: none;
      border: none;
      border-radius: 5px;
      padding: 10px 30px;
      cursor: pointer;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      margin: auto;
      display: block;
      margin-top: 15px;

      &:hover {
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
      }
    }
  }

  > .cellHeadingSubheading {
    color: #7b7b7b;
    font-size: 13px;
  }
}

.phoneNumberInput {
  border: 1px solid #ccc;
  outline: none;
  background-color: white;
  border-radius: 5px;
  padding: 5px 15px;
  font-family: "Open Sans";
  color: #484848;
  font-weight: 300;
}
